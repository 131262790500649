.slide-projects {
	width: 100%;
	height: 70%;
	display: none;
}

.slide-images {
	border: 1px solid black;
	border-radius: 5px;
	width: 100%;
	height: 100%;
}

.title-projects {
	border-radius: 15px;
	box-shadow: 3px 3px 5px black,
	            inset 5px 5px 8px black;
	position: absolute;
	top: 5px;
	left: 5px;
	height: auto;
	padding: 5px 10px 10px;
	text-align: center;
	color: white;
}

.fade {
  -webkit-animation-name: fade;
  -webkit-animation-duration: 2s;
  animation-name: fader;
  animation-duration: 2s;
}

@-webkit-keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}

@keyframes fade {
  from {opacity: .4}
  to {opacity: 1}
}
