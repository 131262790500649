#slide-id {
	height: 100%;
	position: relative;
	display: flex;
  flex-direction: column;
	margin: 0;
}

.slide-tumbnail {
	border-radius: 15px;
	box-shadow: 3px 3px 5px black,
	            inset 5px 5px 8px black;
	width: 100%;
	margin-top: 7px;
}

.slide-position {
	border-radius: 15px;
	box-shadow: 3px 3px 5px black,
	            inset 5px 5px 8px black;
	width: 100%;
	margin-top: 7px;
}

.dotTumbnail {
	display: inline-block;
  height: 65px;
  width: 95px;
  margin: 0 3px;
  background-color: #bbb;
  border-radius: 10%;
  transition: background-color 0.6s ease;
	color: black;
	font-size: 12px;
	text-align: center;
	padding: 1px 0;
}

.dotTumbnail:hover:not(.active) {
	  background-color: #717171;
		transform: scale(1.1);
		cursor: pointer;
}

.activeTumbnail {
	  background-color: #717171;
		opacity: 0.5;
}

.imgTumbnail {
	height: 60px;
  width: 90px;
}

.dot {
	display: inline-block;
  height: 15px;
  width: 15px;
  margin: 0 3px;
  background-color: #bbb;
  border-radius: 50%;
  transition: background-color 0.6s ease;
	color: black;
	font-size: 12px;
	text-align: center;
	padding: 1px 0;
}

.dot:hover:not(.active) {
	  background-color: #717171;
		cursor: pointer;
}

.active {
	  background-color: #717171;
}
